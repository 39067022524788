import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import Footer from "../components/Footer"
import {graphql} from 'gatsby';
import {useTranslation} from 'gatsby-plugin-react-i18next';


export default function Privacy() {
  const {t} = useTranslation();

  return (
    <>
      {/* Terms of Service page meta tags */}
      <Helmet>
        <meta property="og:url" content="/" />
        <meta property="og:title" content="TODO" />
        <meta property="og:description" content="TODO" />
        <meta property="og:image" content="TODO" />
        <meta property="og:image:alt" content="TODO" />
        <meta name="description" content="TODO" />
        <meta name="keywords" content="TODO" />

        <title>Terms of Service</title>
      </Helmet>
      <div className="bg-secondary">
        <Header></Header>
      </div>
      <div className="container py-4">
        <h1>{t("Uslovi korišćenja")}</h1>
        <p>
          Uslovi korišćenja internet sajta Castit definisani su u skladu sa
          Zakonom o obligacionim odnosima, Zakonom o zaštiti potrošača, Zakonom
          o elektronskoj trgovini i dobrim poslovnim običajima. Upotrebom ovog
          internet sajta potvrđujete da ste pročitali, razumeli i prihvatili
          Uslove korišćenja (u daljem tekstu “Uslovi”) i slažete se da ste
          obavezni da ih poštujete, kao i sve druge važeće zakonske propise
          Republike Srbije. Ukoliko ne prihvatate ove Uslove, nemojte koristiti
          ovaj internet sajt.
        </p>
        <p>
          Future Forward DOO Niš, kao punopravni vlasnik internet sajta Castit i
          registrovani privredni za proizvodnju, trgovinu i usluge, zadržava
          pravo da može Uslove izmeniti i dopuniti u bilo kom trenutku. Trebalo
          bi da posetite ovu stranicu s vremena na vreme i proverite ažurne
          Uslove, jer ste njima zakonski vezani i ona će se primenjivati na
          ugovorne odnose u kojima stupate preko ove internet stranice. Ipak,
          ovi Uslovi nisu zamena za pozitivno zakonodavstvo Republike Srbije, i
          slučaju kolizije normi Uslova sa imperativnim odredbama važećih zakona
          primenjivaće se zakonske norme.
        </p>
        <h2>Opšti uslovi korišćenja</h2>
        <p>
          Sadržaji objavljeni na internet sajtu Castit, vlasništvo su firme
          Future Forward DOO iz Niša i mogu se upotrebljavati samo u
          nekomercijalne svrhe, pri čemu se moraju poštovati i sva zakosna
          pravila koja uređuju oblast intelektualne svojine. Nije dozvoljeno
          menjati sadržaj ovog sajta ni na koji način, niti ga reprodukovati ili
          javno prikazivati, izvoditi, distribuirati ili na drugi način
          koristiti u javne ili komercijalne svrhe. Ukoliko želite da koristite
          sadržaje objavljene na ovom internet sajtu u druge svrhe od ovde
          navedenih potrebno je da nas kontaktirate radi dobijanja dozvole.
        </p>
        <p>Kada koristite internet sajt Castit nije dozvoljeno da:</p>
        <ul>
          <li>
            <p>
              Ometate ili onemogućavate bilo koje funkcije povezane sa
              sigurnošću na sajtu ili funkcije koje sprečavaju ili ograničavaju
              upotrebu ili kopiranje dostupnog sadržaja.
            </p>
          </li>
          <li>
            <p>
              Objavljujete ili prenosite na internet sajt svaki materijal koji
              je preteći, klevetnički, nepristojan, uvredljiv, pornografski ili
              zlostavljački. Takođe, materijal kojim možete inicirati rasnu
              mržnju, uzrokujete uznemiravanje ili bilo koje neprijatnosti usled
              kršenja pravila privatnosti i poverenja određenim
              diskriminacionim, pretećim ili izazivačkim komentarima.
            </p>
          </li>
          <li>
            <p>
              Dajete netačne informacije koje se tiču ličnih podataka u vašem
              profilu.
            </p>
          </li>
          <li>
            <p>
              Koristite tuđe lične podatke bez dozvole te osobe ili se lažno
              predstavljate da istupate u ime trećeg lica, firme ili
              organizacije.
            </p>
          </li>
          <li>
            <p>
              Bavite se bilo kojim nezakonitim ili protivpravnim ponašanjem.
            </p>
          </li>
          <li>
            <p>
              Modifikujete, ometate, presrećete ili hakujete internet sajt u
              cilju njegovog onesposobljavanja i/ili sticanja protivpravne
              imovinske koristi.
            </p>
          </li>
          <li>
            <p>
              Koristite specijalizovane programe radi preuzimanja sadržaja sa
              sajta.
            </p>
          </li>
        </ul>
        <p>
          Ukoliko prekršite bilo koju od navedenih zabrana možemo Vas sprečiti
          privremeno ili trajno da koristite ovaj internet sajt. Takođe, možemo
          preduzeti i druge mere, uključujući ali ne ograničavajući se na to da
          prijavimo prestupe nadležnim državnim organima, pošaljemo upozorenje
          ili podnesemo tužbu nadležnom sudu.
        </p>
        <p>
          Linkovi sadržani na internet sajtu Castit koji vode na nezavisne
          sajtove su prikazani isključivo radi Vaše pogodnosti (na primer, link
          ka sajtu proizvođača). Future Forward DOO ne kontroliše i nije
          odgovoran za bilo koji od ovih sajtova ili njihove sadržaje. Future
          Forward DOO nije njihov predstavnik, ne pruža nikakve garancije i ne
          prihvata obaveze u vezi sa ovim sajtovima ili bilo kojim
          informacijama, softverom ili drugim proizvodima koji se tamo mogu
          naći, ili sa rezultatima koji su posledica njihove upotrebe.
        </p>
        <p>
          Pre slanja porudžbine, kupac prihvata Uslove pod kojima se realizuje
          kupovina na daljinu i prihvata saglasnost sa odredbama zakona koji se
          odnose na povraćaj robe kupljene na daljinu a koji se odnose na
          njegove prava i obaveze kao kupca.
        </p>
        <h2>Cene na internet sajtu Castit</h2>
        <p>
          Sve cene na internet sajtu Castit iskazane su u evrima, koje se
          obračunavanju u dinarskoj protivvrednosti po srednjem kursu Nardodne
          Banke Srbije na dan isporuke.
        </p>
        <p>
          Castit zadržava pravo promene cena bez prethodne najave, bilo da se
          radi o povećanju ili smanjenju cena. Navedeno ne važi za artikle na
          akcijama i za sniženja, koji se posebno najavljuju.
        </p>
        <p>
          Iako se trudimo da cene na sajtu budu maksimalno ažurne, može se
          desiti da se potkrade greška. Osobe iz naše firme zadužene za internet
          prodaju obavezno kontaktiraju kupca telefonom ili e-mailom posle svake
          porudžbine, upravo da bi se izbegli nesporazumi i neprijatne
          situacije.
        </p>
        <h2>Isporuka robe</h2>
        <p>
          Isporuka se smatra izvršenom trenutkom kada je kupac preuzeo softver
          na svoj računar, tablet, telefon ili drugi uređaj koji poseduje opcije
          sa skladištanje i/ili instalaciju softvera, ili na trajnom nasoču
          zvuka ili slike.
        </p>
        <h2>Reklamacije</h2>
        <p>
          Reklamacije se podnosi slanjem elektronske pošte na adresu
          info@futureforward.rs. Castit ima lica zadužena za obradu reklamacija
          koja će vam dati odgovor u najkraćem mogućem roku i rado pružiti pomoć
          u takvim situacijama.
        </p>
        <p>
          Kupac je dužan da se pridržava napomena o korišćenju koje je
          proizvođač naveo u uputstvu za korišćenje ili softverskoj licenci.
          Kupac ne može da ostvari svoja prava u slučaju da je fizički oštetio
          proizvod, ako je neispravan proizvod servisiralo nestručno lice ili
          lice koje nije ovlašćeni serviser, ako se nije pridržavao uputstva za
          upotrebu ili pravila softverske licence i tako izazvao kvar uređaja,
          odnosno greške u radu softvera, ili ako je kvar nastao dejstvom tzv.
          više sile (udar groma, poplava, požar itd).
        </p>
        <h2>Politika Privatnosti</h2>
        <p>
          U skladu sa Zakon o zaštiti podataka o ličnosti, podaci koje
          ostavljaju kupci na sajtu koriste se isključivo za komunikaciju u
          slučajevima kupovine robe, reklamacija i informacija za kupce. Castit
          lične podatke kupaca ne koristi za druge namene niti će to raditi.
        </p>
        <p>
          Neke stranice ovog internet sajta bi mogle pohranjivati određene
          pakete informacija poznate kao “cookies” (kolačići) na vašem računaru.
          Kolačići nam daju odgovor na pitanja kako i kada je određena internet
          stranica posećena i koliko ljudi je posetilo. Ova tehnologija ne
          skuplja informacije o vašem ličnom identitetu, umesto toga,
          informacije se nalaze u zbirnoj formi. Svrha ove tehnologije i
          informacija koje ona prikuplja je da nam pomogne da unapredimo naš
          internet sajt i pružimo posetiocima bolje korisničko iskustvo.
        </p>
        <p>
          Korišćenjem veb sajta Castit saglasni ste sa našom politikom
          privatnosti i politikom kolačića, o kojima se detaljnije možete
          informisati na stranici Politika Privatnosti.
        </p>
        <h2>Pravo potrošača na odustanak od ugovora na daljinu</h2>
        <p>
          Prema Zakonu o zaštiti potrošača&nbsp;(“Sl. glasnik RS”, br. 62/2014,
          6/2016 – dr. zakon i 44/2018 – dr. zakon), kupovina preko našeg
          internet sajta smatra se prodajom na daljinu.
        </p>
        <p>
          Zakon za slučaj prodaje na daljinu ustanovljava pravo kupca, koji se
          smatra potrošačem (fizičko lice koje proizvod kupuje radi namirenja
          svojih individualnih potreba, a ne radi obavljanja profesionalne
          delatnosti), da odustane od ugovora u roku od 14 dana od dana kada mu
          je proizvod predat u državinu, odnosno u državinu lica koga je kupac
          odredio, a nije prevoznik. Prilikom odustanka kupac može, ali ne mora
          da navede razloge zbog kojih odustaje. Izjava o odustanku od ugovora
          proizvodi pravno dejstvo od dana kada je poslata trgovcu.&nbsp;Izjava
          o odustanku mora biti svojeručno potpisana.
        </p>
        <p>
          Kupac je dužan da proizvod vrati bez odlaganja, a najkasnije u roku od
          14 dana od dana kada je poslao obrazac za odustanak. Po isteku roka od
          14 dana od dana kada je poslao odustanak, proizvod se više ne može
          vratiti.&nbsp;Troškove povraćaja robe kao i organizaciju transporta
          iste u slučaju odustanka snosi kupac.
        </p>
        <p>
          Moguće je vratiti samo proizvode koji su neoštećeni i, po mogućnosti,
          u originalnoj ambalaži, sa svim dodacima i propratnom dokumentacijom
          (garantni list, uputstva, itd.).
        </p>
        <p>
          Po prijemu proizvoda, utvrdiće se da li je proizvod ispravan i
          neoštećen. Kupac odgovara za neispravnost ili oštećenje proizvoda koji
          su rezultat neadekvatnog rukovanja proizvodom, tj. kupac je isključivo
          odgovoran za umanjenu vrednost proizvoda koja nastane kao posledica
          rukovanja robom na način koji nije adekvatan, odnosno prevazilazi ono
          što je neophodno da bi se ustanovili njegova priroda, karakteristike i
          funkcionalnost. Ukoliko se utvrdi da je nastupila neispravnost ili
          oštećenje proizvoda krivicom kupca, odbiće se vraćanje cene i proizvod
          će mu biti vraćen na njegov trošak.
        </p>
        <p>
          Prodavac je u slučaju zakonitog odustanka od ugovora dužan da kupcu
          bez odlaganja vrati iznos koji je kupac platio po osnovu ugovora, a
          najkasnije u roku od 14 dana od dana kada je primio obrazac za
          odustanak. Prodavac može da odloži povraćaj sredstava dok ne dobije
          robu koja se vraća, ili dok kupac ne dostavi dokaz da je poslao robu
          Prodavcu u zavisnosti od toga šta nastupa prvo.
        </p>
        <p>
          Zakon o zaštiti potrošača isključuje pravo kupca da vrati proizvod u
          određenim situacijama. Neki od tih slučajeva mogu biti relevantni,
          imajući u vidu asortiman robe i usluga u našoj ponudi, a to su:
        </p>
        <ul>
          <li>
            <p>
              pružanja usluga, nakon što je usluga u potpunosti izvršena, ako je
              pružanje usluge počelo nakon izričite prethodne saglasnosti
              potrošača i uz njegovu potvrdu da zna da gubi pravo na odustanak
              od ugovora kada trgovac u potpunosti izvrši ugovor;
            </p>
          </li>
          <li>
            <p>
              isporuka zapečaćenih audio, video zapisa ili računarskog softvera,
              koji su otpečaćeni nakon isporuke;
            </p>
          </li>
          <li>
            <p>
              isporuka robe proizvedene prema posebnim zahtevima potrošača ili
              jasno personalizovane, i
            </p>
          </li>
          <li>
            <p>
              isporuka robe koja je podložna pogoršanju kvaliteta ili ima kratak
              rok trajanja.
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
      </div>

      <Footer></Footer>
    </>
  )
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

